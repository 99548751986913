import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../screens/Home/Home";
import About from "../screens/About/About";
import Event from "../screens/Event/Event";
import Modal from "../screens/Modal/Modal";
import Active from "../screens/Active/Active";
import Contact from "../screens/Contact/Contact";
import Gallery from "../screens/Gallery/Gallery";
import EventPage from "../screens/EventPage/EventPage";
import Volunteer from "../screens/Volunteer/Volunteer";
import GalleryPage from "../screens/GalleryPage/GalleryPage";

const Routings = () => {
  return (
    <Routes>
      <Route path="" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/event" element={<Event />} />
      <Route path="/gallery" element={<Gallery />} />
      <Route path="/gallery-page/:id" element={<GalleryPage />} />
      <Route path="/event-page/:id" element={<EventPage />} />
      <Route path="/active/:id" element={<Active />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/volunteer" element={<Volunteer />} />
      <Route path="/modal" element={<Modal />} />
    </Routes>
  );
};

export default Routings;
