import React from "react";
import "./GalleryCard.css";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

const GalleryCard = (props) => {
  // console.log("GalleryCard", props.element);
  return (
    <>
      <div className="gallery-card">
        <div className="gallerycard-img">
          <Link to={`/gallery-page/${props.element.id}`}>
            <img src={props.element.primary} alt="" />
          </Link>
        </div>
        <div className="gallerycard-content">
          <Link to={`/gallery-page/${props.element.id}`}>
            <div className="gallerycard-title">
              <p>{props.element.name}</p>
            </div>
          </Link>
          <div className="gallerycard-para">
            <p>{parse(props.element.description)}</p>
          </div>
          {/* <div className='gallerycard-read'><Link to={props.link}>Read more</Link></div> */}
          <div className="gallerycont">
            <img src="./images/date.svg" alt="" />
            <p>{props.element.date}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default GalleryCard;
