import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./Menu.css";

const Menu = (props) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(!open);
  };
  return (
    <>
      <div className="menu">
        <div className="menu-back">
          <div className="menu-text">Back</div>
          <div className="menu-icon">
            <img src="./images/cross.svg" onClick={props.hideMenu} />
          </div>
        </div>
        <hr />
        <ul className="menu-items">
          <div className="menu-item">
            <img src="./images/home.svg" />
            <li>
              <NavLink to="/">Home</NavLink>
            </li>
          </div>
          <div className="menu-item">
            <img src="./images/about.svg" />
            <li>
              <NavLink to="/about">About</NavLink>
            </li>
          </div>
          <div className="menu-item">
            <img src="./images/event.svg" />
            <li>
              <NavLink to="/event">Events</NavLink>
            </li>
          </div>
          <div className="menu-item">
            <img src="./images/activity.svg" />
            <li>
              <NavLink to="/gallery">Gallery</NavLink>
            </li>
          </div>
          <div className="menu-item">
            <img src="./images/contact.svg" />
            <li>
              <NavLink to="/contact">Contact</NavLink>
            </li>
          </div>
          {/* <div className='menu-item'>
            <img src='./images/volunteer.svg' />
            <li><Link to='/volunteer'>Volunteer</Link></li>
          </div> */}
          <div className="menu-item">
            <img src="./images/piggy.svg" />
            <div className="dropdown">
              <button onClick={handleOpen} class="dropbtn">
                Donate
              </button>
              {open ? (
                <div className="dropdown-content">
                  <li>
                    <NavLink to="/modal">QR Pay</NavLink>
                  </li>
                </div>
              ) : null}
            </div>
          </div>
        </ul>
      </div>
    </>
  );
};

export default Menu;
