import React, { useState, useEffect } from "react";
import Base from "../../components/Base/Base";
import Header from "../../components/Header/Header";
import "./GalleryPage.css";
import { useParams } from "react-router-dom";
import { galleryOne } from "../../api/Api";

const GalleryPage = () => {
  const { id } = useParams();
  const [details, setDetails] = useState([]);
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });

  useEffect(() => {
    galleryOne(id)
      .then((response) => {
        setDetails(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  console.log("gallery_page", details);
  return (
    <>
      <div className="strip"></div>
      <Base>
        <Header name="Gallery" />
        <div className="gallery_container">
          <div className="gallery_page">
            <div className="gallery_title">{details?.name}</div>
            <div
              className="gallery_description"
              dangerouslySetInnerHTML={{ __html: details?.description }}
            />
            <div className="gallery_image">
              {details?.images?.map((item) => {
                console.log(item);
                return (
                  <div className="gallery_image1">
                    <img src={item.image} alt="" />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Base>
    </>
  );
};

export default GalleryPage;
