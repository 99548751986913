import React from 'react';
import './Scheme.css';

const Scheme = (props) => {
  return (
    <>
      <div className="scheme">
        <div className="scheme-title">{props.title}</div>
        <div className="scheme-para">{props.para}</div>
      </div>
    </>
  );
};

export default Scheme;
