import React from 'react';
import * as yup from 'yup';
import './VolProfile.css';
import Button from '../Button/Button';
import { Formik, Form, Field } from 'formik';

const VolProfile = () => {

  const defaultValue = {
    full_name: '',
    email: '',
    phone: '',
    message: ''
  }

  const validationSchema = yup.object().shape({
    full_name: yup.string().required(),
    email: yup.string().required('Please enter your email').email('Please enter valid email'),
    phone: yup.string().trim().min(10).required('Please enter your mobile number'),
    message: yup.string().required(),
  })

  const submitForm = (e) => {
    console.log(e);
  }

  return (
    <>
      <div className='signup'>
        <div className='signup-name'>
          <p>Become a Volunteer</p>
        </div>
        <Formik initialValues={defaultValue} validationSchema={validationSchema} onSubmit={submitForm}>
          <Form action=''>
            <div className='signup-email'>
              <Field type='text' name='full_name' placeholder='Full Name' />
            </div>
            <div className='signup-email'>
              <Field type='email' name='email' placeholder='Email Id' />
            </div>
            <div className='signup-email'>
              <Field type='phone' name='phone' placeholder='Mobile No.' />
            </div>
            <div className='signup-message'>
              <Field component='textarea' name='message' placeholder='Message' />
            </div>
            <div className='signup-btn'>
              <Button
                type='submit'
                text='Join Now'
              />
            </div>
          </Form>
        </Formik>
      </div>
    </>
  );
};

export default VolProfile;
