import { useState, useEffect } from "react";
import { gallery } from "../api/Api";

const useGallery = () => {
  const [galler, setGaller] = useState([]);
  const [count, setCount] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [load, setLoad] = useState(4);

  const apiCall = () => {
    gallery(pageNo)
      .then((response) => {
        setGaller(response.data.results);
        setCount(response.data.count);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    apiCall();
  }, [pageNo]);

  return { galler, count, pageNo, setPageNo, load, setLoad };
};

export default useGallery;
