import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <div className="footer">
        <div className="foot-content">
          <div className="foot-logo">
            <a target="_blank" href="https://www.facebook.com/loaknath.das.9">
              <p>Connect with</p>
            </a>
            <a target="_blank" href="https://www.facebook.com/loaknath.das.9">
              <img src="./images/fb.svg" alt="" />
            </a>
          </div>
          <ul className="foot-items">
            <li className="foot-item">
              <Link to="/about">About</Link>
            </li>
            <li className="foot-item">
              <Link to="/event">Events</Link>
            </li>
            <li className="foot-item">
              <Link to="/contact">Contact</Link>
            </li>
            <li className="foot-item">
              <Link to="/gallery">Gallery</Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Footer;
