import React from "react";
import "./Event.css";
import Base from "../../components/Base/Base";
import Header from "../../components/Header/Header";
import EvntCard from "../../components/Card/EvntCard/EvntCard";
import ParaLink from "../../components/Para/ParaLink/ParaLink";
import useEvent from "../../hooks/useEvent";

const Event = () => {
  const { evnt } = useEvent();
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });

  return (
    <>
      <div className="strip"></div>
      <Base>
        <Header name="Our Upcoming Events" photo="./images/durga-event.png" />
        <div className="event-container">
          <ParaLink name="Events" />
          <div className="evntcard-container">
            {evnt.map((item) => {
              return <EvntCard key={item.id} element={item} />;
            })}
          </div>
        </div>
      </Base>
    </>
  );
};

export default Event;
