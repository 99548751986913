import React from "react";
import "./Header.css";

const Header = (props) => {
  return (
    <>
      <div className="header">
        <div className="head">
          <div className="head-logo">
            <img src="./images/tmc.svg" alt="" />
            <p>We Stand For DTPS 5th Unit</p>
          </div>
        </div>
        <div
          className="head-city"
          style={{
            backgroundImage: `linear-gradient(90deg, rgba(0, 59, 134, 0.7) 4.04%, rgba(0, 59, 134, 0.483) 81.91%, rgba(0, 59, 134, 0.7) 90.31%), url("${props.photo}")`,
          }}
        >
          <div className="head-cont">
            <div className="head-text">
              <p>{props.name}</p>
            </div>
            <div className="head-loak">
              <img src="./images/loaknath.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
