import React from "react";
import "./About.css";
import useAbout from "../../hooks/useAbout";
import Base from "../../components/Base/Base";
import ParaLink from "../../components/Para/ParaLink/ParaLink";
import Header from "../../components/Header/Header";

const About = () => {
  const { abou } = useAbout();
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
  return (
    <>
      <div className="strip"></div>
      <Base>
        <Header name="About" photo="./images/durga-about.png" />
        <div className="paralink-container">
          <ParaLink name={abou.name} para={abou.text} />
        </div>
      </Base>
    </>
  );
};

export default About;
