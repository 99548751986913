import { useState, useEffect } from "react";
import { aboutList } from "../api/Api";

const useAbout = () => {
  const [abou, setAbou] = useState([]);

  const apiCall = () => {
    aboutList()
      .then((response) => {
        setAbou(response.data);
        // console.table(response.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  useEffect(() => {
    apiCall();
  }, []);
  return { abou };
};

export default useAbout;
