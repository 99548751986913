import React, { useState } from "react";
import "./Navbar.css";
import Menu from "../Menu/Menu";
import { NavLink } from "react-router-dom";
import Modal from "../../screens/Modal/Modal";

const Navbar = () => {
  const [show, setShow] = useState(false);
  const hideMenu = () => {
    setShow(!show);
  };

  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal(!modal);
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(!open);
  };
  return (
    <>
      <div className="nav">
        <div className="navbar">
          <div className="nav-logo">
            <NavLink to="/">Ward #36</NavLink>
          </div>
          <div className="hamburger-menu">
            <label htmlFor="toggle">&#9776;</label>
            <input type="checkbox" id="toggle" onClick={() => setShow(!show)} />
            {show && <Menu hideMenu={hideMenu} />}
          </div>

          <ul className="nav-items">
            <div className="nav_items_link">
              <NavLink to="/" end>
                Home
              </NavLink>
              <NavLink to="/about">About</NavLink>
              <NavLink to="/event">Events</NavLink>
              <NavLink to="/gallery">Gallery</NavLink>
              <NavLink to="/contact">Contact</NavLink>
              {/* <NavLink to="/volunteer">Volunteer</NavLink> */}
            </div>

            <div className="dropdown">
              <button onClick={handleOpen} className="dropbtn">
                Donate
              </button>
              {open ? (
                <div className="dropdown-content">
                  <NavLink to="/modal">QR Pay</NavLink>
                </div>
              ) : null}
            </div>
          </ul>
        </div>
      </div>
      {modal && <Modal toggleModal={toggleModal} />}
    </>
  );
};

export default Navbar;
