import React from 'react';
import Quote from '../Quote/Quote';
import './Mamta.css';

const Mamta = () => {
  return (
    <>
      <div className='mamta'>
        <div className='mamta-box'>
          <Quote />
          <div className='mamta-details'>
            <div className="mamta-text">
              <p>আমরা যারা প্রগতিশীলদের সমর্থন করি</p>
              <p>আমরা যারা মানুষের পাশে থাকি</p>
              <p>আমরা যারা যুব সমাজের কে নিয়ে সমাজ গঠন করি</p>
              <div className="slogan">
                <p>মা মাটি মানুষ</p>
                <img src="./images/wb-logo.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Mamta;
