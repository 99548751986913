import React from 'react';
import './Quote.css';

const Quote = () => {
  return (
    <>
      <div className="quote">
        <div className="quote-box">
          <p>Building a strong foundation for our city to grow long into the future.</p>
        </div>
      </div>
    </>
  );
};

export default Quote;
