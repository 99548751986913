import React from 'react';
import './Alscheme.css'
import Scheme from '../Scheme/Scheme';

const Alscheme = () => {
  return (
    <>
      <div className="alsceme">
        <div className="alsceme-box">
          <Scheme title='Kanyashree Prakalpa' para='120+ Girls benefited' />
          <Scheme title='Swasthya Sathi Scheme' para='1500+ Women Benefited' />
          <Scheme title='Rupashree Prakalpa' para='80+ Girls Benefited' />
          <Scheme title='Lakshmir Bhandar Scheme' para='2500+ Women Benefited' />
          <Scheme title='Nirmal Bangla Scheme' para='1000+ People Benefited' />
          <Scheme title='Khadya Sathi Scheme' para='120+ People Benefited' />
          {/* <Scheme title='Snehalaya Prakalpa' para='120+ Girls benefited' />
          <Scheme title='Utkarsh Bangla' para='120+ Girls benefited' />
          <Scheme title='Sneher Paras ' para='120+ Girls benefited' /> */}
        </div>
      </div>
    </>
  );
};

export default Alscheme;
