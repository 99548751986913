import React from "react";
import { Link } from "react-router-dom";
import "./EvntCard.css";
import parse from "html-react-parser";

const EvntCard = (props) => {
  // console.log(name);
  return (
    <>
      <div className="evnt-card">
        <Link to={`/event-page/${props?.element?.id}`}>
          <div className="evntcard-title">{props?.element?.name}</div>
        </Link>
        <div className="evntcard-para">
          {parse(props?.element?.description)}
        </div>
        <div className="evntcard-lower">
          <div className="evntcard-logo">
            <img src="./images/map.svg" />
            <p>{props?.element?.venue}</p>
          </div>
          <div className="evntcard-logo">
            <img src="./images/date.svg" alt="" />
            <p>{props?.element?.date}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default EvntCard;
