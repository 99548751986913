import React from "react";
import "./Contact.css";
import Base from "../../components/Base/Base";
import Header from "../../components/Header/Header";
import ParaLink from "../../components/Para/ParaLink/ParaLink";
import useContact from "../../hooks/useContact";
import Contacts from "../../components/Contacts/Contacts";

const Contact = () => {
  const { contac } = useContact();
  // console.log("contac", contac);
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
  return (
    <>
      <div className="strip"></div>
      <Base>
        <Header
          name="Feel Free To Connect With Me"
          photo="./images/durga-contact.png"
        />
        <div className="paralink-contact">
          <ParaLink name={contac.name} para={contac.text} />
        </div>
      </Base>
    </>
  );
};

export default Contact;
