import { useState, useEffect } from "react";
import { contact } from "../api/Api";

const useContact = () => {
  const [contac, setContac] = useState([]);

  const apiCall = () => {
    contact()
      .then((response) => {
        setContac(response.data);
        // console.table(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    apiCall();
  }, []);
  return { contac };
};

export default useContact;
