import React from 'react';

const Button = ({
  width = '15rem',
  height = '4rem',
  color = '#FFFFFF',
  cursor = 'pointer',
  borderRadius = '0.5rem',
  borderColor = '#003B86',
  background = '#003B86',
  fontWeight = '500',
  fontSize = '1.6rem',
  lineHeight = '1.9rem',
  type = 'submit',
  text = 'Buy',
  event = () => { }
}) => {
  return (
    <button
      className='btn'
      style={{
        width: width,
        height: height,
        color: color,
        cursor: cursor,
        background: background,
        borderRadius: borderRadius,
        borderColor: borderColor,
        fontWeight: fontWeight,
        fontSize: fontSize,
        lineHeight: lineHeight,
        type: type,
        text: text
      }}
      onClick={event}
    >{text}</button>
  );
};

export default Button;
