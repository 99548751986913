import React from "react";
import "./ParaArc.css";

const ParaArc = (props) => {
  // console.log("evntpge", props);

  return (
    <>
      <div className="paraarc">
        <div className="arc_upper">
          <h1>{props?.element?.name}</h1>
          <div className="arcdate">
            <p className="arc-date">{props?.element?.date}</p>
            {/* <img src="./images/share.svg" /> */}
          </div>
        </div>
        <div
          className="arcpara"
          dangerouslySetInnerHTML={{ __html: props?.element?.description }}
        />
      </div>
    </>
  );
};

export default ParaArc;
