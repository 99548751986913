import React from "react";
import "./Paginate.css";

const Paginate = ({
  prev = () => {},
  next = () => {},
  pageNo = "1",
  totalPage = "1",
}) => {
  return (
    <div>
      <div className="paginate">
        <img
          src="../images/previous.svg"
          onClick={() => {
            prev();
          }}
        />
        <div className="paginate-text">
          <p>Page</p>
          <p className="paginate-num">{pageNo}</p>
          <p>of {totalPage}</p>
        </div>
        <img
          src="../images/Next.svg"
          onClick={() => {
            next();
          }}
        />
      </div>
    </div>
  );
};

export default Paginate;
