import React from "react";
import "./ParaLink.css";

const ParaLink = (props) => {
  return (
    <>
      <div className="paralink-details">
        <div className="paralink-title">
          <h3>{props.name}</h3>
          <div className="paralink-dash"></div>
        </div>
        <div className="paralink-para">
          <div dangerouslySetInnerHTML={{ __html: props.para }} />
        </div>
      </div>
    </>
  );
};

export default ParaLink;
