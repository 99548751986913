import React from 'react';
import { HashRouter } from 'react-router-dom';
import './App.css';
import Routings from './Routings/Routings';


const App = () => {
  return (
    <HashRouter>
      <Routings/>
    </HashRouter>
  );
}

export default App;