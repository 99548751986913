import React from 'react';
import './Profile.css';

const Profile = () => {
  return (
    <>
      <div className="profile">
        <img src="./images/profile.png" alt="" />
        <div className="profile-bio">“My family instilled in me the value of hard work, service and above all responsibility for our ourselves, our community and our future”</div>
        <div className="profile-name">Loaknath Das</div>
        <div className="profile-job">
          <p>Ex Borough Chairman - 05</p>
          <p>Ex Councillor Ward # 36</p>
        </div>
      </div>
    </>
  );
};

export default Profile;
