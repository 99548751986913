import { useState, useEffect } from "react";
import "./Home.css";
import { eventList } from "../../api/Api";
import { useParams } from "react-router-dom";
import Base from "../../components/Base/Base";
import { useNavigate } from "react-router-dom";
import useGallery from "../../hooks/useGallery";
import Mamta from "../../components/Mamta/Mamta";
import Profile from "../../components/Profile/Profile";
import Button from "../../components/Button/Button";
import Header from "../../components/Header/Header";
import ActCard from "../../components/Card/GalleryCard/GalleryCard";
import ParaLink from "../../components/Para/ParaLink/ParaLink";
import EvntCard from "../../components/Card/EvntCard/EvntCard";
import Alscheme from "../../components/Strategy/Alscheme/Alscheme";

const Homepage = () => {
  const { galler } = useGallery();
  const navigate = useNavigate();
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });

  const [evnt, setEvnt] = useState([]);
  const type = useParams();

  const apiCall = () => {
    eventList()
      .then((response) => {
        setEvnt(response.data.results);
        console.table(response.data.results);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    apiCall();
  }, [type]);

  return (
    <>
      <div className="strip"></div>
      <Base>
        <Header
          name="Development and Prosperity for the People of Durgapur, ward #36"
          photo="./images/durga-home.png"
        />
        <Profile />
        <Alscheme />
        <Mamta />
        <ParaLink name="Gallery" />
        <div className="actcard-container">
          {galler.map((art) => {
            return (
              <ActCard
                image={art.image}
                name={art.name}
                description={art.description}
                date={art.date}
                key={art.id}
                element={art}
              />
            );
          })}
        </div>
        <div className="button">
          <Button
            text="View All"
            borderColor="#003B86"
            fontWeight="600"
            event={() => {
              navigate("/gallery");
            }}
          />
        </div>
        <ParaLink name="Events" />
        <div className="evntcard-container">
          {evnt.map((item) => {
            return <EvntCard key={item.id} element={item} />;
          })}
        </div>
        <div className="button1">
          <Button
            text="View All"
            borderColor="#003B86"
            fontWeight="600"
            event={() => {
              navigate("/event");
            }}
          />
        </div>
      </Base>
    </>
  );
};

export default Homepage;
