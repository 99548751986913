import axios from "axios";
const API = "https://api.durgapurpaschim.in/api/v1/";

export const aboutList = async () => {
  const config = {
    method: "get",
    url: `${API}cms/about-us/`,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios(config);
};

export const eventList = async () => {
  const config = {
    method: "get",
    url: `${API}event/`,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios(config);
};

export const eventOne = async (id) => {
  const config = {
    method: "get",
    url: `${API}event/${id}`,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios(config);
};

export const gallery = async (pageNo) => {
  const config = {
    method: "get",
    url: `${API}gallery/?page_size=6&page=${pageNo}`,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios(config);
};

export const galleryOne = async (id) => {
  const config = {
    method: "get",
    url: `${API}gallery/${id}`,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios(config);
};

export const activeList = async () => {
  const config = {
    method: "get",
    url: `${API}activeList/`,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios(config);
};

export const contact = async () => {
  const config = {
    method: "get",
    url: `${API}cms/contact-us/`,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios(config);
};
