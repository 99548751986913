import React from "react";
import "./Volunteer.css";
import Base from "../../components/Base/Base";
import VolProfile from "../../components/VolProfile/VolProfile";
import ParaLink from "../../components/Para/ParaLink/ParaLink";
import Header from "../../components/Header/Header";

const Volunteer = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
  return (
    <>
      <Base>
        <Header
          title="Become a Volunteer"
          photo="./images/durga-volunteer.png"
        />
        <div className="paralink-vol">
          <ParaLink title="help" />
        </div>
        <div className="volprofile">
          <VolProfile />
        </div>
      </Base>
    </>
  );
};

export default Volunteer;
