import React, { useState, useEffect } from "react";
import "./EventPage.css";
import { useParams } from "react-router-dom";
import Base from "../../components/Base/Base";
import Header from "../../components/Header/Header";
import ParaArc from "../../components/Para/ParaArc/ParaArc";
import { eventOne } from "../../api/Api";

const EventPage = () => {
  const { id } = useParams();
  const [details, setDetails] = useState();
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });

  useEffect(() => {
    eventOne(id)
      .then((response) => {
        setDetails(response?.data);
        console.log("event_page", response?.data);
      })
      .catch((error) => {
        console.log(error?.message);
      });
  }, []);

  return (
    <>
      <div className="strip"></div>
      <Base>
        <Header name={details?.name} />
        <div className="event_para_active">
          <ParaArc key={details?.id} element={details} />
        </div>
      </Base>
    </>
  );
};

export default EventPage;
