import { useState, useEffect } from "react";
import { eventList } from "../api/Api";
import { useParams } from "react-router-dom";

const useEvent = () => {
  const [evnt, setEvnt] = useState([]);
  const type = useParams();

  const apiCall = () => {
    eventList()
      .then((response) => {
        setEvnt(response.data.results);
        console.table(response.data.results);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    apiCall();
  }, [type]);
  return { evnt };
};

export default useEvent;
