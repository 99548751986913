import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import "./Modal.css";

const Modal = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="modal">
        <div className="modalbar">
          <h2>Please Scan Here For Donation</h2>
          <img src="./images/qr pay.jpeg" alt="" />
          <div className="modal_btn">
            <Button
              width="10rem"
              height="3.5rem"
              background="#ff9900"
              borderColor="transparent"
              text="Close"
              event={() => {
                navigate("/");
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
