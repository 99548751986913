import React from "react";
import "./Gallery.css";
import Paginate from "../../components/Paginate/Paginate";
import Base from "../../components/Base/Base";
import ParaLink from "../../components/Para/ParaLink/ParaLink";
import GalleryCard from "../../components/Card/GalleryCard/GalleryCard";
import Header from "../../components/Header/Header";
import useGallery from "../../hooks/useGallery";

const Gallery = () => {
  const { galler, pageNo, setPageNo, load, setLoad, count } = useGallery();
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });

  return (
    <>
      <div className="strip"></div>
      <Base>
        <Header name="Gallery" photo="./images/durga-contact.png" />
        <div className="active-container">
          <ParaLink name="Gallery" />
          <div className="activecard-container">
            {galler.map((item) => {
              console.log("Gallery", item);
              return <GalleryCard key={item.id} element={item} />;
            })}
          </div>
          <div className="paginate-content">
            <Paginate
              prev={() => {
                if (load > 6) {
                  setPageNo(pageNo - 1);
                  setLoad(load - 6);
                }
              }}
              next={() => {
                if (load < count) {
                  setPageNo(pageNo + 1);
                  setLoad(load + 6);
                }
                console.log(pageNo);
              }}
              pageNo={pageNo}
              totalPage={Math.ceil(count / 6)}
            />
          </div>
        </div>
      </Base>
    </>
  );
};

export default Gallery;
