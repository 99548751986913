import React, { useState, useEffect } from "react";
import "./Active.css";
import axios from "axios";
import { useParams } from "react-router-dom";
import Base from "../../components/Base/Base";
import Header from "../../components/Header/Header";
import ParaArc from "../../components/Para/ParaArc/ParaArc";

const Active = () => {
  const { id } = useParams();
  const [details, setDetails] = useState([]);
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });

  const getDate = () => {
    axios
      .get(`https://api.durgapurpaschim.in/api/v1/activity/${id}`)
      .then((response) => {
        setDetails(response.data);
        // console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getDate();
  }, [id]);

  return (
    <>
      <div className="strip"></div>
      <Base>
        <Header name={details.name} />
        <div className="para-active">
          <ParaArc name={details.name} date={details.date} />
        </div>
      </Base>
    </>
  );
};

export default Active;
